/*
 * @Author: 陈俊升 761723995@qq.com
 * @Date: 2023-11-03 14:43:16
 * @LastEditors: 陈俊升 761723995@qq.com
 * @LastEditTime: 2024-03-25 09:05:41
 * @FilePath: /hr_new_vue3/src/utils/constants.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/* 存放一些全局的常量 */

// 人事异动常量
export const PERSONNEL_CHANGE = [
  {
    id: 1,
    label: '兼任'
  },
  {
    id: 2,
    label: '调岗'
  },
  {
    id: 3,
    label: '晋升'
  },
  {
    id: 4,
    label: '降级'
  }
  // {
  //   id: 1,
  //   label: '入职'
  // },
  // {
  //   id: 2,
  //   label: '转正'
  // },
  // {
  //   id: 3,
  //   label: '晋升'
  // },
  // {
  //   id: 4,
  //   label: '调岗'
  // },
  // {
  //   id: 5,
  //   label: '降级'
  // },
  // {
  //   id: 6,
  //   label: '离职'
  // },
  // {
  //   id: 7,
  //   label: '退休'
  // },
  // {
  //   id: 8,
  //   label: '返聘'
  // }
];

// 薪酬调整常量
export const SALARY_CHANGE = [
  {
    id: 5,
    label: '加薪'
  },
  {
    id: 6,
    label: '降薪'
  }
  // {
  //   id: 9,
  //   label: '入职定薪'
  // },
  // {
  //   id: 10,
  //   label: '转正调薪'
  // },
  // {
  //   id: 11,
  //   label: '调岗调薪'
  // },
  // {
  //   id: 12,
  //   label: '晋升调薪'
  // },
  // {
  //   id: 13,
  //   label: '降级调薪'
  // },
  // {
  //   id: 14,
  //   label: '离职结算'
  // }
];

// 合同异动常量
export const CONTRACT_TYPE_CHANGE = [
  {
    id: 1,
    label: '变更原因'
  },
  {
    id: 2,
    label: '解除原因'
  },
  {
    id: 3,
    label: '终止原因'
  }
];

// 所有的异动常量
export const TRANSACTION = [
  {
    id: 1,
    label: '兼任'
  },
  {
    id: 2,
    label: '调岗'
  },
  {
    id: 3,
    label: '晋升'
  },
  {
    id: 4,
    label: '降级'
  },
  {
    id: 5,
    label: '加薪'
  },
  {
    id: 6,
    label: '降薪'
  }
];
// 所有的异动常量 上面那个跟现在不一致 复制一个
export const NEWTRANSACTION = [
  {
    id: 3,
    label: '兼任'
  },
  {
    id: 9,
    label: '调岗'
  },
  {
    id: 10,
    label: '晋升'
  },
  {
    id: 11,
    label: '降级'
  },
  {
    id: 7,
    label: '加薪'
  },
  {
    id: 8,
    label: '降薪'
  },
  {
    id: 14,
    label: '转正'
  }
];
// 异动审批常量
export const TRANSACTION_APPROVE = [
  {
    id: 1,
    label: ''
  },
  {
    id: 2,
    label: '审批中',
    color: 'green'
  },
  {
    id: 3,
    label: '审批通过',
    color: 'blue'
  },
  {
    id: 4,
    label: '审批拒绝',
    color: 'red'
  },
  {
    id: 5,
    label: '无需审批',
    color: 'gray'
  },
  {
    id: 6,
    label: '完成异动',
    color: 'blue'
  },
]

// 异动子集类型
export const TRANSACTION_TYPE = [
  {
    id: 1,
    label: '异动基本信息'
  },
  {
    id: 2,
    label: '兼任信息'
  },
  {
    id: 3,
    label: '合同信息'
  },
  {
    id: 4,
    label: '薪酬福利'
  },
  {
    id: 5,
    label: '考勤信息'
  }
]

// 离职类型常量
export const LEAVE_TYPE = [
  { key: 1, label: '辞职' },
  { key: 2, label: '辞退' },
  { key: 3, label: '开除' },
  { key: 4, label: '解职' },
  { key: 5, label: '非正常离职' },
  { key: 6, label: '自离' },
  { key: 7, label: '退休' }
];

// 工作性质/员工性质常量
export const NATURE_OF_WORK = [
  { key: '实习', value: '实习' },
  { key: '兼职', value: '兼职' },
  { key: '全职', value: '全职' },
  { key: '劳务派遣', value: '劳务派遣' },
  { key: '退休返聘', value: '退休返聘' },
  { key: '劳务外包', value: '劳务外包' }
];

// 薪酬模式常量
export const SALARY_MODE = [
  { key: '计件制', value: '计件制' },
  { key: '计时制', value: '计时制' },
  { key: '职等工资制', value: '职等工资制' },
  { key: '年薪制', value: '年薪制' },
  { key: '协议工资制', value: '协议工资制' }
];

// 添加日程待办的提醒时间常量
export const REMIND_TIME = [
  {
    label: '不提醒',
    time: 0,
    value: 1
  },
  {
    label: '提前5分钟',
    time: 5,
    value: 2
  },
  {
    label: '提前15分钟',
    time: 15,
    value: 3
  },
  {
    label: '提前30分钟',
    time: 30,
    value: 4
  },
  {
    label: '提前1小时',
    time: 60,
    value: 5
  },
  {
    label: '提前2小时',
    time: 120,
    value: 6
  },
  {
    label: '提前1天',
    time: 1440,
    value: 7
  },
  {
    label: '提前2天',
    time: 2880,
    value: 8
  }
];

// 标签常量的颜色
export const TAG_COLOR_ENUMS = [
  'red',
  'blue',
  'green',
  'orange',
  'purple',
  'pink'
];

// 证件类型
export const ID_TYPE = ['身份证', '护照'];

// 默认头像颜色
export const DEFAULT_AVATAR_COLOR = ['#165dff', '#82d62c', '#f5b45b'];
